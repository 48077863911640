export const useSeo = (seoInput) => {
  const i18nHead = useLocaleHead()
  const setI18nParams = useSetI18nParams()

  // Se il parametro seo non è reattivo lo rendo reattivo in modo da uniformare il codice
  const seo = isRef(seoInput) ? seoInput : ref(seoInput)
  const meta = seo.value?.meta || []

  // Se i parametri localeParams sono settati hreflang e canonical prendono gli slug tradotti
  /*
  {
    it: { slug: 'slug-1'},
    en: { slug: 'slug-2'},
    'en-us': { slug: 'slug-3'}
    ...
  }
  */
  if (seo.value?.localeParams) {
    setI18nParams(seo.value?.localeParams)
  }

  return useHead({
    title: seo.value?.title || '',
    meta: [...i18nHead.value.meta, ...meta],
    htmlAttrs: { ...i18nHead.value.htmlAttrs },
    link: [...i18nHead.value.link],
  })
}
